<template>
<span>
  Version: {{version}}
  <v-btn
    v-show='reloadRequired'
    small success dark
    style='position:fixed; top: 55px; right:15px;z-index:100'
    @click='reload()' >Update available. Click to reload</v-btn>
</span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Version',
  data () {
    return {
      version: null,
      latestVersion: null,
      reloadRequired: false
    }
  },
  mounted () {
    this.getVersion()
    setInterval(this.getVersion, 300000)
  },
  methods: {
    getVersion () {
      // get from localStorage
      if (localStorage && localStorage.getItem('version')) {
        this.version = localStorage.getItem('version')
      }
      this.$emit('input', this.version)
      axios.get('/about.json')
       .then((result) => {
         let latestVersion = result.data.version
         console.log(latestVersion)
         if (latestVersion !== this.version) {
           caches.keys().then((keys) => { for (let key of keys) { caches.delete(key) } })
           localStorage.setItem('version', latestVersion)
           if (this.version) { this.reloadRequired = true }
           this.version = latestVersion
           // window.location.reload()
         }
       })
    },
    reload () {
      window.location.reload()
    }
  }
}
// caches.keys().then((keys) => { for (key of keys) { caches.delete(key) } })
// window.location.reload()
</script>
