import Vue from 'vue'
const  TOKEN_KEY = 'kong_appointmentguru_token'

export default {
  namespaced: true,
  state: {
    token: null,
    loginRedirect: '/'
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      // if (process.env.NODE_ENV === 'development') {
      //   Vue.$cookies.set(TOKEN_KEY, token, '24d', null)
      // } else {
      //   Vue.$cookies.set(TOKEN_KEY, token, '24d', null, "appointmentguru.co")
      // }
    },
    setLoginRedirect(state, path) {
      if (path !== '/login') {
        state.loginRedirect = path
      }
    }
  },
  actions: {
    initSession ({dispatch}) {
      let token = Vue.$cookies.get(TOKEN_KEY)
      if (token) {
        dispatch('logUserIn', token)
      }
    },
    logUserIn ({commit}, token) {
      commit('setToken', token)
      let domain = 'appointmentguru.co'
      if (process.env.NODE_ENV === 'development') { domain = null }
      Vue.$cookies.remove(TOKEN_KEY, null, domain)
    },
    logUserOut ({commit}, path) {
      if (path) {
        commit('setLoginRedirect', path)
      }
      let domain = 'appointmentguru.co'
      if (process.env.NODE_ENV === 'development') { domain = null }
      Vue.$cookies.remove(TOKEN_KEY, null, domain)
      commit('setToken', null)
    }
  },
  getters: {
    auth (state) {
      return state.token
    },
    isLoggedIn (state) {
      if (state.token && state.token !== 'null') {
        return true
      } else {
        return false
      }
    },
    redirectPath (state) {
      if (state.loginRedirect) { return state.loginRedirect }
      return '/'
    }
  }
}
