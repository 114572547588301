<template>
<v-container >
  <v-card tile >
    <v-card-title class='subtitle-1' >
      Welcome to your AppointmentGuru dashboard
      <v-spacer />
      <v-btn small icon @click='get' :loading='req.loading' ><v-icon>mdi-refresh</v-icon></v-btn>
    </v-card-title>
    <!-- <v-card-text>
      <strong>Coming soon.</strong>
      {{daterange}} | {{daterangeText}}
      The dashboard will give you a birdseye view of your business. Allowing you run ad-hoc reports, view beautiful dashboards and download your data.
    </v-card-text> -->
  </v-card>
  <!-- <pre v-text='spaces' /> -->
  <v-row wrap class='mt-3' >
    <v-col v-for='(space, index) in spaces' :key='space.id' cols=6 >
      <v-hover v-slot="{ hover }" >
        <v-card :elevation="hover ? 20 : 2" height="100%" >
          <space-stats-card
            v-model='spaces[index]'
            :subtitle='daterangeText' />
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import SpaceStatsCard from '../components/cards/SpaceStatsCard.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Home',
  components: { SpaceStatsCard },
  data () {
    return {
      req: {
        resource: 'ag.spaces.dashbaord'
      }
    }
  },
  computed: {
    ...mapGetters(['daterange', 'daterangeText', 'dateRangeParams']),
    ...mapGetters('identity', ['lookups']),
    spaces () {
      return this.getObjectPath(this.req, 'result.results', [])
      // let spaces = this.getObjectPath(this.lookups, 'spaces', {})
      // return Object.values(spaces)
    }
  },
  mounted () {
    this.get()
  },
  methods: {
    get () {
      this.req.params = this.dateRangeParams
      this.$request('get', this.req)
    }
  },
  watch: {
    daterange () {
      this.get()
    }
  }
}
</script>
