<template>
<div>
  <template v-if='request.loading' >
    <v-progress-linear
      indeterminate height='2' class='ma-0 pa-0' >
    </v-progress-linear>
  </template>

  <template v-else >
    <server-errors
      v-if='request.status === 400'
      :request="request" >
    </server-errors>
    <v-alert v-else-if='request.status >= 300 && message' dense dismissible tile
      class='pa-2 ma-0'
      :type='messageType'
      :value='true' >
      {{message}}
    </v-alert>
  </template>
  <!-- <pre>{{request}}</pre> -->
  <v-snackbar v-model='showSnacker' :color='messageType' >{{message}}</v-snackbar>
</div>
</template>
<script>
import ServerErrors from './ServerErrors'
export default {
  name: 'RequestStatus',
  components: {ServerErrors},
  props: {
    request: { type: Object, default: () => { return {} } },
    successTimeout: { type: Number, default: 1200 },
    message200: { default: 'Success' },  // string or false (to hide the message)
    message201: { default: 'Object created' },  // string or false (to hide the message)
    message204: { type: String, default: 'Successfully deleted' },
    message404: { type: String, default: 'Resource not found' },
    message400: { type: String, default: 'There was a problem with your request. Please check your inputs and try again' },
    message401: { type: String, default: 'Permission denied' },
    message403: { type: String, default: 'Permission denied' },
    message500: { type: String, default: 'Server error. Please try again later or contact support if the problem persists' }
  },
  data () {
    return {
      showSnacker: false
    }
  },
  watch: {
    requestStatus () {
      let isSuccessRequestStatus = (this.requestStatus > 199 && this.requestStatus < 300)
      this.$emit(this.requestStatus.toString(), this.request)
      if (isSuccessRequestStatus) {
        this.$emit('ok', this.request)
        if (this.requestStatus === 200 && this.message200 === false) {  // explicitly ignore 200
          return
        }
        if (this.requestStatus === 201 && this.message201 === false) {  // ignore 201
          return
        }
        this.showSnacker = true
      } else {
        this.$emit('error', this.request)
      }
    }
  },
  computed: {
    requestStatus () {
      return this.request.status
    },
    messageType () {
      let statusMap = {
        200: 'success',
        201: 'success',
        204: 'success',
        404: 'warning',
        401: 'warning',
        403: 'warning',
        400: 'error',
        500: 'error',
        502: 'error'
      }
      return statusMap[this.request.status]
    },
    message () {
      let message = null
      let key = `message${this.request.status}`
      if (key in this) { message = this[key] }
      return message
    }
  }
}
</script>
