import {mapGetters} from 'vuex'
import { DateTime } from 'luxon'

export default {
  data () {
    return {
      global: {
        request: {
          me: {
            resource: 'ag.practitioner.me'
          },
          lookups: {
            resource: 'ag.practitioner.me.lookup'
          }
        }
      }
    }
  },
  methods: {
    getObjectPath (object, path, defaultResponse) {
      if (object) {
        let bits = path.split('.')
        for (let key of bits) {
          try {
            if (Object.keys(object).indexOf(key) !== -1) {
              object = object[key]
            } else {
              return defaultResponse
            }
          } catch (e) {
            if (e instanceof TypeError) {
              return defaultResponse
            }
            throw e
          }
        }
        return object
      }
      return defaultResponse
    },
    dateformat (dt, preset, format) {
      if (typeof(dt) === 'string') {
        dt = DateTime.fromISO(dt)
      }
      if (preset) {
        return dt.toLocaleString(DateTime[preset])
      }
      if (format) {
        return dt.toFormat(format)
      }
      return dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    },
    isRealEmail (email) {
      if (!email) { return false } // empty is not a real email

      if (email.endsWith('@appointmentguru.co')) {
        // phone number style fake emails
        if (email.startsWith('+')) { return false }

        // uuid style fake emails
        let bits = email.split('@')
        if (bits.length < 2) { return false }
        if (bits[0].length === 36) { return false }

        // user+uuid
        if (bits[0].length === 41 && bits[0].startsWith('user+')) { return false }
      }
      return true
    },
    monthrange (month) {
      let start = DateTime.local().startOf('month').toISODate()
      if (month) {
        start = month + '-01'
      }
      let end = DateTime.fromISO(start).endOf('month').toISODate()
      return [start, end]
    },
    formatCurrency (currency, number, locale = 'en-ZA') {
      if (!currency) { currency = this.defaultCurrency }
      if (currency) {
        let formatter = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency
        })
        return formatter.format(number)
      } else {
        return number
      }
    },
    clone (dta) {
      return JSON.parse(JSON.stringify(dta))
    },
    init () {
      this.getMe()
      this.lookup()
    },
    getMe () {
      this.$request('get', this.global.request.me)
    },
    lookup () {
      this.$request('get', this.global.request.lookups)
    },
    getProfilePicture (p) {
      if (p) {
        if (p.profile_picture) { return p.profile_picture }
        if (p.profile && p.profile.profile_picture) {
          return p.profile.profile_picture
        }
      }
      return null
    },
    getInitials(fullname, numLetters) {
      if (!numLetters) { numLetters = 2 }
      if (fullname) {
        let items = fullname.split(' ')
        if (items && items.length > 0) {
          return items.slice(0, numLetters).map((word) => { return word[0] }).join('')
        }
      }
      return null
    }
  },
  watch: {
    isLoggedIn () {
      // see: mixins/global.js
      this.init()
    },
    globalMeRequestStatus () {
      if (this.global.request.me.status === 200) {
        this.$store.commit('identity/setMe', this.global.request.me.result)
      }
    },
    globalLookupRequestStatus () {
      if (this.global.request.lookups.status === 200) {
        this.$store.commit('identity/setLookups', this.global.request.lookups.result)
      }
    }
  },
  computed: {
    ...mapGetters('identity', [
      'me',
      'lookups',
      'getSpaceById'
    ]),
    ...mapGetters('auth', [
      'auth',
    ]),
    defaultCurrency () {
      return 'ZAR'
    },
    spaceData () {
      if (this.spaceId) {
        return this.getSpaceById(this.spaceId)
      }
      return {}
    },
    spaceName() {
      return this.getObjectPath(this.spaceData, 'title', '')
    },
    globalMeRequestStatus () {
      return this.getObjectPath(this.global, 'request.me.status', 0)
    },
    globalLookupRequestStatus() {
      return this.getObjectPath(this.global, 'request.lookups.status', 0)
      // return this.global.request.lookups.status
    }
  }
}
