import store from '@/store/index.js'

/* See @/components/registry to find registered components */

export default {
  methods: {
    launchSpacePicker () { },
    launchLocationForm (spaceId, locationId) {
      console.log(spaceId, locationId)
      let options = {
        id: 'location-form',
        title: 'Location',
        visible: true,
        componentAttrs: { spaceId, locationId },
        attrs: { fullscreen: true }
      }
      store.commit('setActiveDialog', options)
    },
    launchOperatingHoursForm(operationHours) {
      let options = {
        id: 'operation-hours-form',
        title: 'Oerating Hours',
        visible: true,
        componentAttrs: {
          value: operationHours,
          withFab: true
        },
        attrs: { fullscreen: false, width: '460px' }
      }
      store.commit('setActiveDialog', options)
    },
    launchRuleForm() { },
    launchQuickCreateAppointmentType (spaceId) {
      let options = {
        id: 'quick-appointment-type-form',
        title: 'Add an appointment type',
        visible: true,
        componentAttrs: { spaceId },
        attrs: { fullscreen: true }
      }
      store.commit('setActiveDialog', options)
    },
    launchAppointmentTypeForm (spaceId, productId, spaceName) {
      if (!spaceName) { spaceName = 'Appointment type'}
      let options = {
        id: 'appointment-type-form',
        title: spaceName,
        visible: true,
        componentAttrs: { spaceId, productId },
        attrs: { fullscreen: true }
      }
      store.commit('setActiveDialog', options)
    }
  }
}
