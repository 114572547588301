import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import HttpMixin from './mixins/http'
import VueCookies from 'vue-cookies'
import GlobalMixin from './mixins/global.js'
import LauncherMixin from './mixins/launcher.js'
import RequestStatus from './components/ui/RequestStatus'

Vue.use(VueCookies)
Vue.mixin(HttpMixin)
Vue.mixin(GlobalMixin)
Vue.mixin(LauncherMixin)

Vue.component('request-status', RequestStatus)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
