import store from '@/store'

export default {
  loginRequired (to, from, next) {
    store.dispatch('auth/initSession')
    if (to.query.t) {
      // console.log(to.query.t)
      let token = to.query.t
      store.dispatch('auth/logUserIn', token)
    }
    if (!store.getters['auth/isLoggedIn'] === true) {
      store.commit('auth/setLoginRedirect', to.path)
      return next('/login')
    }
    return next()
  }
}
