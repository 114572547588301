<template>
<v-row dense flat >
  <v-spacer />
  <v-btn small :outlined='month.selected' :text='!month.selected'
    v-for='month in months'
    :key='month.text'
    class='mt-1'
    @click='$store.commit("setDateRange", [month.start, month.end])' >
    {{month.text}}
  </v-btn>
  <v-btn @click='datepicker = true' outlined small class='mt-1 ml-1' v-text='daterangeText' ></v-btn>
  <v-dialog v-model='datepicker' width='360' >
    <v-card>
      <v-card-title class='subtitle-1 overline' >
        {{daterangeText}}
        <v-spacer />
        <v-btn @click="datepicker=false" icon small ><v-icon small >mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-tabs v-model='activeTab' >
          <v-tab >Month</v-tab>
          <v-tab >Custom range</v-tab>
          <v-tabs-items v-model='activeTab' >
            <v-tab-item >
              <!-- {{selectedmonth}} / {{daterange}} -->
              <v-date-picker full-width no-title
                v-model="selectedmonth" type='month' />
            </v-tab-item>
            <v-tab-item >
              <v-date-picker full-width no-title range
                v-model="dates" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</v-row>
</template>
<script>
import {mapGetters} from 'vuex'
import {DateTime} from 'luxon'

export default {
  name: 'DateRangePicker',
  props: {
    withMonths: { type: Boolean, default: false },
    numMonths: { type: Number, default: 3 }
  },
  data () {
    return {
      activeTab: 0,
      datepicker: false,
      selectedmonth: null
    }
  },
  watch: {
    selectedmonth () {
      this.dates = this.monthrange(this.selectedmonth)
    }
  },
  methods: {
    getMonth (month) {
      let selected = false
      if (this.daterange && this.daterange.length > 0) {
        selected = (DateTime.fromISO(this.daterange[0]).month === month.month)
      }
      return {
        selected,
        dt: month,
        text: month.monthShort,
        start: month.startOf('month').toISODate(),
        end: month.endOf('month').toISODate()
      }
    }
  },
  computed: {
    ...mapGetters(['daterange', 'daterangeText']),
    months () {
      let dt = DateTime.local()
      let months = []
      for (let x = 0; x < this.numMonths; x++) {
        let thisMonth = dt.minus({months: (this.numMonths - x)})

        months.push(this.getMonth(thisMonth))
      }
      months.push(this.getMonth(dt))
      return months
    },
    dates: {
      get () {
        return this.daterange
      },
      set (value) {
        this.$store.commit('setDateRange', value)
      }
    }
  }
}
</script>
