<template>
<v-app >
  <v-navigation-drawer v-model='sidebar' v-if='isLoggedIn && withSideBar' dark app >
    <v-toolbar dense flat dark >
      <v-icon color='amber' small class='mr-2'>mdi-chart-box</v-icon>
      <v-toolbar-title >
        <span class='red--text font-weight-medium darken-3' >Guru</span>Numbers
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click='sidebar=false' icon ><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-list dense >
      <v-divider></v-divider>
      <template v-for='navitem in navitems' >
        <v-subheader
          v-if='navitem.type === "subheader"'
          :key='navitem.text' >
          {{navitem.text}}
        </v-subheader>
        <v-list-item v-else exact
          :to='navitem.path'
          :key='navitem.id'
          class='mr-3' >
          <v-list-item-content>
            <v-list-item-title v-text='navitem.text' />
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- <v-subheader >
        Missions
        <v-chip color="amber" class='ml-4 justify-right' small dark >
          Coming soon
        </v-chip>
      </v-subheader> -->
    </v-list>
    <template v-slot:append>
        <div class="pa-2">
          <v-btn @click='logout' block>Logout</v-btn>
        </div>
      </template>
  </v-navigation-drawer>

  <v-app-bar dense flat extended app dark
    v-if="isLoggedIn"
    color='orange' >
    <v-icon v-if='withSideBar' @click='sidebar=!sidebar' class='mr-2' >mdi-menu</v-icon>
    <v-toolbar-title >
      <v-btn text to='/' >AppointmentGuru</v-btn>
    </v-toolbar-title>
    <v-chip class='ml-2' label small color='teal' >dashboard</v-chip>
    <v-chip class='ml-2' label small color='amber' >Early Access</v-chip>
    <v-spacer></v-spacer>
    <template v-if='isLoggedIn' >
      <v-divider vertical ></v-divider>
      <v-btn v-if='meId' small text >
        <v-avatar :size='32' class='mr-2' color='teal' >
          <img v-if='meProfile' :src="meProfile" />
          <span v-else >{{ meInitials }}</span>
        </v-avatar>
        {{meFullname}}
      </v-btn>
    </template>
    <date-range-picker slot='extension' />
  </v-app-bar>
  <request-status v-if="isLoggedIn" :message200="false" :request='global.request.me' />
  <request-status v-if="isLoggedIn" :message200="false" :request='global.request.lookups' />

  <!-- Sizes your content based upon application components -->
  <v-main class='grey lighten-2' >
    <v-container fluid>
      <router-view @fullpage='sidebar=false'></router-view>
    </v-container>
  </v-main>

  <v-dialog v-if='activeDialog' v-model='showDialog' v-bind='activeDialog.attrs' >
    <v-card>
      <v-toolbar dense flat dark color='orange' >
        <v-toolbar-title v-text='activeDialog.title'></v-toolbar-title>
        <v-spacer />
        <v-btn @click='showDialog = false' icon ><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <!-- {{dialogComponent}} -->
      <v-component
        :is='dialogComponent'
        v-bind='activeDialog.componentAttrs'
        @saved='globalDialogSaveEvent'
        @close='showDialog=false' />
    </v-card>
  </v-dialog>
  <v-footer class='caption' >
    Know you business
    <v-spacer />
    <version />
  </v-footer>
</v-app>
</template>

<script>
import ComponentRegistry from '@/components/registry.js'
import DateRangePicker from '@/components/ui/DateRangePicker.vue'
import Version from '@/components/ui/Version.vue'

import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {DateRangePicker, Version},
  data: () => ({
    sidebar: null,
    withSideBar: true,
    navitems: [
      { id: 'home', text: 'Home', path: '/' },
      // { type: 'subheader', text: 'Dashboards' },
      // { id: 'spaces-home', text: 'Spaces', path: '/settings/spaces', exact: true },
      // { id: 'spaces-practitioners', text: 'Practitioners', path: '/settings/practitioners', exact: true },
      // { type: 'subheader', text: 'Tools' },
      // { id: 'tools-invoices', text: 'Bulk invoicing', path: '/tools/invoices', exact: true },
      { type: 'subheader', text: 'Data' },
      { id: 'data-appointments', text: 'Appointments', path: '/data/appointments', exact: true },
      { id: 'data-invoices', text: 'Invoices', path: '/data/invoices', exact: true },
      { id: 'data-clients', text: 'Clients', path: '/data/clients', exact: true }
      // { type: 'subheader', text: 'Annual reports' }
    ]
  }),
  mounted () {
    let today = DateTime.local()
    let fromDate = today.startOf('month').toISODate()
    let toDate = today.endOf('month').toISODate()
    this.$store.commit('setDateRange', [fromDate, toDate])
  },
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn'
    ]),
    ...mapGetters('identity', [
      'me',
      'meId',
      'meFullname',
      'meProfile',
      'meInitials'
    ]),
    dialogComponent () {
      let componentId = this.getObjectPath(this.activeDialog, 'id')
      if (componentId) {
        if (componentId in ComponentRegistry) {
          console.log(componentId)
          return ComponentRegistry[componentId].component
        } else {
          console.error(`${componentId} is not registerd in @/components/registry.js`)
        }
      }
      return null
    },
    activeDialog () {
      return this.$store.getters.activeDialog
    },
    showDialog: {
      get: function () {
        return this.$store.getters.activeDialog.visible
      },
      set: function (isVisible) {
        this.$store.commit('setDialogVisible', isVisible)
      }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logUserOut')
      this.$router.push({path: '/login'})
    },
    globalDialogSaveEvent (data) {
      let key = this.$store.getters.activeDialog.id
      this.$store.commit('setEvent', {key, data})
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler () {
        if (this.isLoggedIn) {
          this.init()
        }
      }
    }
  }
};
</script>
