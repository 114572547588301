import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/mixins/auth.js'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Index.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: Auth.loginRequired
  },
  {
    path: '/spaces/:spaceId',
    name: 'Space',
    component: () => import(/* webpackChunkName: "space-dash" */ '../views/dashboards/SpaceBoard.vue'),
    props: true,
    beforeEnter: Auth.loginRequired
  },
  {
    path: '/data/appointments',
    name: 'Appointments',
    component: () => import(/* webpackChunkName: "appointments-data" */ '../views/data/Appointments.vue'),
    props: true,
    beforeEnter: Auth.loginRequired
  },
  {
    path: '/data/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients-data" */ '../views/data/Clients.vue'),
    props: true,
    beforeEnter: Auth.loginRequired
  },
  {
    path: '/data/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "invoices-tools" */ '../views/data/Invoices.vue'),
    props: true,
    beforeEnter: Auth.loginRequired
  }
]

const router = new VueRouter({
  routes
})

export default router
