import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/auth.js'
import Identity from './modules/identity.js'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    'auth': Auth,
    'identity': Identity
  },
  state: {
    theme: {
      dark: false
    },
    dialog: {
      id: null,
      title: '',
      visible: false,
      attrs: {},
      component: null,
      componentAttrs: null
    },
    daterange: [],
    events: {}
  },
  mutations: {
    setDateRange(state, daterange) {
      state.daterange = daterange
    },
    setDarkMode (state, isDark) {
      Vue.set(state.theme, 'dark', isDark)
    },
    setActiveDialog (state, { id, title, visible, component, attrs, componentAttrs }) {
      state.dialog = { id, title, visible, component, attrs, componentAttrs }
    },
    setDialogVisible (state, isVisible) {
      Vue.set(state.dialog, 'visible', isVisible)
    },
    setEvent (state, {key, data}) {
      let touch = Math.random()
      Vue.set(state.events, key, {touch, data})
    }
  },
  actions: {
  },
  getters: {
    daterange (state) {
      return state.daterange
    },
    dateRangeParams(state, getters) {
      let params = {}
      if (getters.daterange && getters.daterange.length === 2) {
        params = {
          from_date: getters.daterange[0],
          to_date: getters.daterange[1]
        }
      }
      return params
    },
    daterangeText (state, getters) {
      let range = getters.daterange
      if (range.length === 0) {
        return 'Select date range'
      }
      if (range.length === 1) {
        return range[0] + ' - ...'
      }
      if (range.length === 2) {
        return range[0] + ' - ' + range[1]
      }
      return ''
    },
    activeDialog (state) {
      return state.dialog
    },
    isDark(state) {
      return state.theme.dark
    }
  }
})
