<template>
<v-card dark :flat='flat' v-bind='attrs' color='primary darken-4' height="100%" >
  <v-card-title class='subtitle-1 overline' >
    {{value.title}}
    <small class='ml-3' v-if='subtitle' v-text='subtitle' />
    <v-spacer />
    <v-btn :to='`/spaces/${value.id}/`' text small >View report</v-btn>
  </v-card-title>
  <v-card-text>
    <v-row>
      <v-col v-for='(value, title) in metrics' :key='title' >
        <h2 class='mb-0 pb-0 font-weight-regular'>
          <span v-if='title === "count"' v-text='value' />
          <span v-else >
            {{ formatCurrency('ZAR', value) }}
          </span>
        </h2>
        <v-subheader class='pa-0 overline font-weight-light' v-text='title' ></v-subheader>
      </v-col>
      <!-- <v-col >
        <h2 class='mb-0 pb-0 font-weight-regular'>{{ formatCurrency('ZAR', 10463.67) }}</h2>
        <v-subheader class='pa-0 overline font-weight-light' >Invoiced</v-subheader>
      </v-col>
      <v-col >
        <h2 class='mb-0 pb-0 font-weight-regular'>{{ formatCurrency('ZAR', 6245.00) }}</h2>
        <v-subheader class='pa-0 overline font-weight-light' >Received</v-subheader>
      </v-col> -->
    </v-row>
  </v-card-text>
  <vue-apex-charts
    v-if='series'
    :options="options"
    :series="series"
    :height=160 />
  <!-- <pre v-text='series' /> -->
</v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'SpaceStatsCard',
  components: {VueApexCharts},
  props: {
    value: { type: Object, default: () => { return {} } },
    flat: { type: Boolean, default: true },
    subtitle: { type: String, required: false },
    attrs: { type: Object, default: () => {} }
  },
  methods: {
    randomrange () {
      let r = []
      let x = 0
      while (x < 10) {
        r.push(Math.floor(Math.random() * Math.floor(100)))
        x++
      }
      return r
    }
  },
  data () {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
          type: 'area',
          sparkline: {
            enabled: true,
            height: 100
          }
        },
        tooltip: {
          theme: 'dark'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2
        }
      }
    }
  },
  computed: {
    metrics () {
      return this.getObjectPath(this.value, 'metrics', {})
    },
    series () {
      return [
        {
          name: 'earned',
          data: this.getObjectPath(this.value, 'timeseries.value', [])
        },
        {
          name: 'received',
          data: this.getObjectPath(this.value, 'timeseries.collected', [])
        }
      ]
    }
  }
}
</script>
