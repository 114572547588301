// import AppointmentTypeForm from '@/components/actions/AppointmentTypeForm.vue'
// import NewAppointmentTypeWizard from '@/components/actions/wizards/NewAppointmentTypeWizard.vue'

// import LocationForm from '@/components/actions/LocationForm.vue'
// import OperatingHours from '@/components/actions/OperatingHoursForm.vue'

export default {
  // 'quick-appointment-type-form': {
  //   component: NewAppointmentTypeWizard,
  //   index: 'appointment type new create product'
  // },
  // 'appointment-type-form': {
  //   component: AppointmentTypeForm,
  //   index: 'appointment type product'
  // },
  // 'location-form': {
  //   component: LocationForm,
  //   index: 'space location address'
  // },
  // 'operation-hours-form': {
  //   component: OperatingHours,
  //   index: 'operating hours times'
  // }
}
