/**

1. Provide your resources in a global config file
2. Provide a request in your data:
3. Feed that request into your desired method.

e.g.:

```
data () {
  return requests: {
    foo: {
      resource:
    }
  }
}
```
 */
import axios from 'axios'
import Vue from 'vue'

const SERVICES = {
  authenticationguru: process.env.VUE_APP_AUTHENTICATIONGURU_API_ROOT,
  appointmentguru: process.env.VUE_APP_APPOINTMENTGURU_API_ROOT,
  invoiceguru: process.env.VUE_APP_INVOICEGURU_API_ROOT
}

const RESOURCE = {
  // authguru:
  'auth.login': {
    base: SERVICES.authenticationguru,
    path: '/login/'
  },

  // AppointmentGuru
  'ag.auth.set-password': {
    url: 'api/auth/set-password/',
    method: 'POST',
    requiresAuth: true,
    params: {
      phone_number: { type: 'number', required: true },
      otp: { type: 'number', required: true }
    }
  },
  'ag.practitioner.me': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/practitioner/practitioners/me/'
  },
  'ag.practitioner.me.lookup': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/practitioner/practitioners/me/lookups/'
  },
  'ag.spaces.dashbaord': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/spaces/dashboard/'
  },
  'ag.spaces': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/spaces/'
  },
  'ag.practitioner.appointments': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/practitioner/appointments/'
  },
  'ag.practitioner.relationships': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/practitioner/appointments/relationships/'
  },
  'ag.dashboard.relationships': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/dashboard/relationships/'
  },
  // 'ag.practitioner.rules': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/practitioner/rules/'
  // },
  // 'ag.practitioner.hours': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v2/practitioner/services/hours/'
  // },
  // 'ag.spaces.practitioner': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/practitioner/spaces/'
  // },
  'ag.spaces.relationships': {
    base: SERVICES.appointmentguru,
    path: '/api/v3/practitioner/relationships/',
    detailMutation: 'addClientDetail'
  },
  // 'ag.spaces.products': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v2/practitioner/services/products/'
  // },
  // 'ag.spaces.settings': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/spaces/:spaceId/settings/'
  // },
  // 'ag.spaces.members': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/spaces/:spaceId/members/'
  // },
  // 'ag.spaces.appointmenttypes': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/spaces/:spaceId/appointment-types/'
  // },
  // 'ag.spaces.addresses': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/spaces/:spaceId/addresses/'
  // },
  // 'ag.spaces': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v3/spaces/'
  // },
  // 'ag.integrations': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v2/practitioner/integrations/'
  // },
  // 'ag.integrations.setmore': {
  //   base: SERVICES.appointmentguru,
  //   path: '/api/v2/practitioner/integrations/setmore/'
  // },
  // // unibox:
  // 'unibox.templates': {
  //   base: SERVICES.unibox,
  //   path: '/templates/'
  // },
  // 'unibox.comms': {
  //   base: SERVICES.unibox,
  //   path: '/communications/'
  // },
  // // invoiceguru:
  // 'invoiceguru.transactions': {
  //   base: SERVICES.invoiceguru,
  //   path: '/transactions/'
  // },
  'invoiceguru.invoices': {
    base: SERVICES.invoiceguru,
    path: '/invoices/'
  }
}

function getUrl (resource, resourceId = null, actionId = null, extra = null, kwargs = null) {
  if (!(resource in RESOURCE)) {
    console.error(resource + ' not found in RESOURCE')
    console.error(RESOURCE)
  }
  let config = RESOURCE[resource]

  if (!('base' in config)) {
    console.error('base not defined in config: ', config)
  }
  let resourceUrl = `${config.base}${config.path}`

  if (resourceId) {
    resourceUrl += resourceId + '/'
  }
  if (actionId) {
    resourceUrl += 'actions/' + actionId + '/'
  }
  if (extra) {
    resourceUrl += extra + '/'
  }
  if (kwargs) {
    Object.keys(kwargs).forEach((k) => {
      resourceUrl = resourceUrl.replace(`:${k}`, kwargs[k])
    })
  }
  return resourceUrl
}

export default {
  data () {
    return {
      $RESOURCES: RESOURCE
    }
  },
  methods: {
    requestAuthHeaders (service=null) {
      console.log(service)
      let headers = {
        authorization: 'Bearer ' + this.$store.getters['auth/auth'],
        'content-type': 'application/json'
      }
      return headers
    },
    async makeRequest (config, options) {
      try {
        if (options && options.method === 'get' && config.loading && (!config.params || Object.keys(config.params).length === 0)) {
          console.log('skipping request GET ' + config.url)
          return null
        }

        Vue.set(config, 'loading', true)
        Vue.set(config, 'status', '0')
        Vue.set(config, 'errors', {})

        if (options.params) {
          options.params = new URLSearchParams(options.params)
        }
        let res = await axios(options)
        if (res && res.status) {
          if (res.status > 199) {
            Vue.set(config, 'result', res.data)
            Vue.set(config, 'status', res.status)
            Vue.set(config, 'error', false)
            Vue.set(config, 'loading', false)
            if (RESOURCE[config.resource].detailMutation) {
              if (config.resourceId && !config.extra) {
                this.$store.commit(RESOURCE[config.resource].detailMutation, res.data)
              }
            }
          }
        }
        return res
      } catch (error) {
        if (error.response.status === 401) {
          // if (error.response.detail === 'Authentication credentials were not provided.') {
          if (this.$router.path !== '/login') {
            this.$store.dispatch('auth/logUserOut', this.$route.path)
            this.$router.push('/login')
          }
          // }
        }
        if (error.response) { // non 2xx status code
          Vue.set(config, 'errors', error.response.data)
          Vue.set(config, 'status', error.response.status)
        } else if (error.request) { // The request was made but no response was received
          Vue.set(config, 'errors', { message: 'Server error occured' })
          Vue.set(config, 'status', 500)
        } else {
          Vue.set(config, 'status', 500)
        }
        Vue.set(config, 'error', true)
        Vue.set(config, 'loading', false)
      }
    },
    async $request (action, config) {
      /* this.request('get', config) */
      if (action === 'get') {
        return this.__fetch(config)
      }
      if (action === 'save') {
        return this.__save(config)
      }
      if (action === 'delete') {
        return this.__delete(config)
      }
      console.error(`${action} is not a supported action. Try: 'get', 'save'`)
    },
    async __fetch (config) {
      /*
       * Perform a GET request.
       * Usage:
       * :param config: config is a request definition
       * let options = { resource: 'ag.spaces', resourceId: 9, extra: 'stream/'}
       * fetch(options) // GET /api/v3/practitioner/spaces/9/stream/
       *
       */
      let url = getUrl(config.resource, config.resourceId, null, config.extra, config.kwargs)
      let headers = this.requestAuthHeaders()
      let method = 'get'
      let params = {}
      if (config.params) {
        params = config.params
      }
      let options = { url, headers, method, params }
      return this.makeRequest(config, options)
    },
    async __delete (config) {
      let url = getUrl(config.resource, config.resourceId, null, config.extra, config.kwargs)
      let headers = this.requestAuthHeaders()
      let method = 'delete'
      let params = {}
      if (config.params) {
        params = config.params
      }
      let data = {}
      if (config.data) {
        data = config.data
      }
      let options = { url, headers, method, params, data }
      return this.makeRequest(config, options)
    },
    async __save (config) {
      let url = getUrl(config.resource, config.resourceId, null, config.extra, config.kwargs)
      let headers = this.requestAuthHeaders()
      let method = 'post'
      if (config.resourceId) {
        method = 'patch'
      }
      if (config.method) { // if explicitly specified overwrite implicit
        method = config.method
      }
      let params = {}
      if (config.params) {
        params = config.params
      }
      let data = {}
      if (config.data) {
        data = config.data
      }
      let options = { url, headers, method, params, data }
      return this.makeRequest(config, options)
    },
    // /api/v3/admin/practitioners/1/actions/convert_practitioner/
    async getAction (config) {
      return this.performAction(config, {}, 'get')
    }
    // async performAction (config, data = {}, method = 'post') {
    //   let url = getUrl(config.resource, config.resourceId, config.actionId)
    //   let headers = this.requestAuthHeaders()
    //   let options = { url, headers, method, data }
    //   return this.makeRequest(config, options)
    // }
  }
}
