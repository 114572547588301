<template>
<v-alert class="pa-1 lighten-1 mt-0 mb-0"
    v-if='request.status > 299'
    dark error value='showAlert' dismissible >
    <v-list dense dark
      v-if='errorsIsIterable'
      class='error elevation-10 mx-2 mb-2' >
      <v-subheader dark class='overline'>{{title}}</v-subheader>
      <template v-for='(errors, field) in errors' >
        <v-subheader dark class='overline ma-0' v-if='field' :key='field' >
          {{ field.split("_").join(" ") }}
        </v-subheader>
        <v-divider :key='`div-${field}`' ></v-divider>
        <v-list-tile v-for='error in [].concat(errors)' :key='error' >
          <v-list-tile-content>
            <v-list-tile-title v-text='error' >
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
    <!-- <ul v-if='errorsIsIterable' >
      <li v-for='(errors, field) in errors' :key='field' >
        <strong v-if='field' >{{ field.split("_").join(" ") }}</strong>:
        <ul>
          <li v-for='error in [].concat(errors)' :key='error' >{{error}}</li>
        </ul>
      </li>
    </ul> -->
    <div v-else >{{errors}}</div>
</v-alert>
</template>
<script>
export default {
  name: 'ServerErrors',
  props: {
    request: { type: Object, required: true },
    title: { type: String, default: 'There where some errors with your request' },
    alertType: { type: String, default: 'error' }
  },
  computed: {
    errorsIsIterable () {
      if (!this.errors) { return false }
      if (typeof this.errors === 'string') { return false }
      return true
    },
    errors () {
      if (this.request && this.request.errors) {
        return this.request.errors
      }
      if (this.request && this.request.result && this.request.result.data) {
        return this.request.result.data
      }
      if (this.request && this.request.data) {
        return this.request.data
      }
      if (this.request && this.request.result) {
        return this.request.result
      }
      return null
    },
    showAlert () {
      return this.errors.length > 0
    }
  }
}
</script>
