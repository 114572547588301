import Vue from 'vue'

function getObjectPath (object, path, defaultResponse) {
  if (object) {
    let bits = path.split('.')
    for (let key of bits) {
      try {
        if (Object.keys(object).indexOf(key) !== -1) {
          object = object[key]
        } else {
          return defaultResponse
        }
      } catch (e) {
        if (e instanceof TypeError) {
          return defaultResponse
        }
        throw e
      }
    }
    return object
  }
  return defaultResponse
}

export default {
  namespaced: true,
  state: {
    me: {},
    lookups: {
      locations: {},
      practitioners: {},
      spaces: {}
    }
  },
  mutations: {
    setMe (state, data) {
      Vue.set(state, 'me', data)
    },
    setLookup(state, { key, values }) {
      Vue.set(state.lookups, key, values)
    },
    setLookups (state, data) {
      Vue.set(state, 'lookups', data)
    }
  },
  getters: {
    me (state) {
      return state.me
    },
    meId (state, getters) {
      return getObjectPath(getters.me, 'user.id')
    },
    meFullname (state, getters) {
      let str = ''
      let firstName = getObjectPath(getters.me, 'user.first_name')
      let lastName = getObjectPath(getters.me, 'user.last_name')
      if (firstName) { str = firstName }
      if (lastName) { str += ' ' + lastName }
      return str
    },
    meProfile (state, getters) {
      if (getters.meId) {
        let p = getters.getPractitionerById(getters.meId)
        if (p && p.profile_picture) {
          return p.profile_picture
        }
        return null
      }
    },
    meInitials (state, getters) {
      if (getters.meFullname) {
        let items = getters.meFullname.split(' ')
        if (items && items.length > 0) {
          return items.slice(0, 2).map((word) => { return word[0] }).join('')
        }
      }
      return '??'
    },
    lookups (state) {
      return state.lookups
    },
    spaces (state) {
      return state.lookups.spaces
    },
    spacesList (state, getters) {
      let spaces = getters.spaces
      if (spaces) { return Object.values(spaces) }
      return []
    },
    spacesIOwn (state, getters) {
      // let meId = getters.meId
      return getters.spacesList.filter((space) => {
        return space.role === 'Owner'
      })
    },
    getLocationsBySpaceId: (state, getters) => (spaceId) => {
      let space = getters.getSpaceById(spaceId)
      if (space) {
        return space.locations
      }
      return []
    },
    getLocationBySpaceAndId: (state, getters) => (spaceId, locationId) => {
      let locs = getters.getLocationsBySpaceId(spaceId)
      return locs.find((loc) => {
        return String(loc.id) === String(locationId)
      })
    },
    practitionersList (state, getters) {
      if (getters.lookups && getters.lookups.practitioners) {
        return  Object.values(getters.lookups.practitioners)
      }
      return []
    },
    getPractitionersById: (state, getters) => (ids) => {
      return ids.map((id) => {
        return getters.getPractitionerById(id)
      })
    },
    getPractitionerById: (state, getters) => (id) => {
      let lookups = getters.lookups
      if (lookups && lookups.practitioners) {
        return lookups.practitioners[id]
      }
      return {}
    },
    getPractitionersInSpace: (state, getters) => (spaceId) => {
      return getters.practitionersList.filter((p) => {
        console.log(p.space, spaceId)
        return String(p.space) === String(spaceId)
      })
    },
    getSpaceById: (state, getters) => (id) => {
      let lookups = getters.lookups
      if (lookups && lookups.spaces) {
        return lookups.spaces[id]
      }
      return {}
    },
    getProductById: (state, getters) => (id) => {
      let lookups = getters.lookups
      if (lookups && lookups.products) {
        return lookups.products[id]
      }
      return {}
    }
  }
}
